export const os = [
  {
    name: 'Windows',
    version: [
      'Windows 7',
      'Windows 8',
      'Windows 8.1',
      'Windows 10',
      'Windows 11',
      'Others',
    ],
  },
  {
    name: 'MacOs',
    version: [
      'OS X 10.9 (Mavericks)',
      'OS X 10.10 (Yosemite)',
      'OS X 10.11 (El Capitan)',
      'macOS 10.12 (Sierra)',
      'macOS 10.13 (High Sierra)',
      'macOS 10.14 (Mojave)',
      'macOS 10.15 (Catalina)',
      'macOS 11 (Big Sur)',
      'Others',
    ],
  },
  {
    name: 'Linux',
    version: [
      'Ubuntu',
      'Deepin',
      'Pop!_Os',
      'Parrot Os',
      'Arch Linux',
      'Solus',
      'Kali Linux',
      'Nitrux',
      'Others',
    ],
  },
  {
    name: 'Others',
    version: [],
  },
]
export const processor = [
  'Intel Core i3',
  'Intel Core i5',
  'Intel Core i7',
  'Intel Core i9',
  'AMD Ryzen 9',
  'AMD Ryzen 7',
  'AMD Ryzen 5',
  'AMD Ryzen 3',
  'M1',
  'M1 Pro',
  'M1 Max',
  'M1 Ultra',
  'M2',
  'M2 Pro',
  'M2 Max',
  'Others',
]
export const ram = ['2', '4', '8', '16', '32', 'Others']
