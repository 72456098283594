import React, { useContext, useState, useMemo } from 'react'

import Select from '../../components/MiniComponents/Select'
import InputWrapper from '../../components/MiniComponents/InputWrapper'
import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import Input from '../../components/controls/Input'
import { Button, StyledScreen } from './RemoteScreenOne'

import { ModalContext } from '../../context/ModalContext'

import { os, processor, ram } from '../../constants/hardwareSpecs'

const osNames = os.map((e) => e.name)

const RemoteScreenTwo = () => {
  const { setRemotePage, compSpec, setCompSpec, setReachRemotePage } =
    useContext(ModalContext)

  const [otherOS, setOtherOS] = useState(false)
  const [otherOSVersion, setOtherOSVersion] = useState(false)
  const [otherProcessor, setOtherProcessor] = useState(false)
  const [otherRAM, setOtherRAM] = useState(false)

  const osVersions = useMemo(() => {
    return compSpec.os
      ? os.filter((e) => e.name === compSpec.os)[0]?.version
      : os.filter((e) => e.name === osNames[0])[0]?.version
  }, [compSpec.os])

  const handleOtherOS = (e) =>
    setCompSpec((prevState) => ({
      ...prevState,
      os: e.target.value,
      osVersion: '',
    }))
  const handleSelectOS = (value) => {
    if (value === 'Others') {
      setOtherOS(true)
      setCompSpec((prevState) => ({
        ...prevState,
        os: value,
        osVersion: '',
      }))
    } else {
      setCompSpec((prevState) => ({
        ...prevState,
        os: value,
        osVersion: '',
      }))
      setOtherOS(false)
    }
  }
  const handleSelectOSVersion = (value) => {
    if (value === 'Others') {
      setOtherOSVersion(true)
      setCompSpec((prevState) => ({
        ...prevState,
        osVersion: value,
      }))
    } else {
      setCompSpec((prevState) => ({
        ...prevState,
        osVersion: value,
      }))
      setOtherOSVersion(false)
    }
  }
  const handleOtherOSVersion = (e) =>
    setCompSpec((prevState) => ({
      ...prevState,
      osVersion: e.target.value,
    }))
  const handleProcessor = (value) => {
    if (value === 'Others') {
      setOtherProcessor(true)
      setCompSpec((prevState) => ({ ...prevState, processor: value }))
    } else {
      setCompSpec((prevState) => ({ ...prevState, processor: value }))
      setOtherProcessor(false)
    }
  }
  const handleOtherProcessor = (e) =>
    setCompSpec((prevState) => ({
      ...prevState,
      processor: e.target.value,
    }))
  const handleRAM = (value) => {
    if (value === 'Others') {
      setOtherRAM(true)
      setCompSpec((prevState) => ({ ...prevState, ram: value }))
    } else {
      setCompSpec((prevState) => ({ ...prevState, ram: value }))
      setOtherRAM(false)
    }
  }
  const handleOtherRAM = (e) =>
    setCompSpec((prevState) => ({
      ...prevState,
      ram: e.target.value,
    }))
  const handleGoBack = () => {
    setRemotePage(1)
    setReachRemotePage(1)
  }
  const handleNext = () => {
    setRemotePage(3)
    setReachRemotePage(3)
  }

  return (
    <StyledScreen>
      <h2 className="subHeading">Computer Specifications</h2>
      <p className="paragraph mb-2">
        Add your computer specifications to help us find the best match
      </p>

      {/* Operating System */}
      <InputWrapper label="Operating System">
        <Select
          value={otherOS ? 'Others' : compSpec.os}
          handleSelect={handleSelectOS}
          data={osNames}
          placeholder="Choose an option"
        />
        {otherOS && (
          <Input
            style={{ marginTop: '1rem' }}
            onChange={handleOtherOS}
            placeholder="Enter your Operating system"
          />
        )}
      </InputWrapper>

      {/* Os Version */}
      <InputWrapper label="OS Version">
        {otherOS ? (
          <Input
            style={{ marginTop: '1rem' }}
            onChange={handleOtherOSVersion}
            placeholder="Enter your Operating system version"
          />
        ) : (
          <>
            <Select
              value={otherOSVersion ? 'Others' : compSpec.osVersion}
              handleSelect={handleSelectOSVersion}
              data={osVersions}
              placeholder="Choose an option"
            />
            {otherOSVersion && (
              <Input
                style={{ marginTop: '1rem' }}
                onChange={handleOtherOSVersion}
                placeholder="Enter your Opereating system version"
              />
            )}
          </>
        )}
      </InputWrapper>

      {/* Processor */}
      <InputWrapper label="Processor">
        <Select
          value={otherProcessor ? 'Others' : compSpec.processor}
          handleSelect={handleProcessor}
          data={processor}
          placeholder="Choose an option"
        />
        {otherProcessor && (
          <Input
            style={{ marginTop: '1rem' }}
            onChange={handleOtherProcessor}
            placeholder="Enter your Processor"
          />
        )}
      </InputWrapper>

      {/* RAM */}
      <InputWrapper label="RAM">
        <Select
          value={compSpec.ram}
          handleSelect={handleRAM}
          data={ram}
          placeholder="Choose an option"
        />

        {otherRAM && (
          <Input
            style={{ marginTop: '1rem' }}
            onChange={handleOtherRAM}
            placeholder="Enter your RAM"
          />
        )}
      </InputWrapper>

      <FlexWrapper content="flex-end" mt="2rem">
        <Button onClick={handleGoBack}>Go Back</Button>
        <Button
          className="filled"
          disabled={
            compSpec.os === '' ||
            compSpec.osVersion === '' ||
            compSpec.processor === '' ||
            compSpec.ram === ''
          }
          onClick={handleNext}
        >
          Next Step
        </Button>
      </FlexWrapper>
    </StyledScreen>
  )
}

export default RemoteScreenTwo
